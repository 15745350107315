<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>
            <b-form-select
              id="input-lg"
              value-field="nickname"
              text-field="nickname"
              size="lg"
              v-model="selectNickname"
            >
              <b-form-select-option
                v-for="(proj, i) in this.projectList"
                v-bind:key="i"
                :value="{ code: proj.code, text: proj.nickname }"
              >
                {{ proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>
            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Name"
              v-model="selectedProject.project_name"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Reporting BOM</label>
            <b-form-select
              v-model="selectedProject.type_document"
              :options="options.documentTypeOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="onShowData(false)">
            <i class="flaticon2-search-1"></i> Show
          </button>

          <button class="btn btn-warning btn-md ml-2" @click="onShowData(true)">
            <i class="flaticon2-search-1"></i> Show Latest
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-end">
        <vue-excel-xlsx
          :data="data.bomdesign"
          :columns="columns"
          :file-name="
            selectedProject.code_project +
              '-' +
              '' +
              selectedProject.nickname +
              '' +
              '- BOM Design'
          "
          :sheet-name="'letter'"
          :file-type="'xlsx'"
        >
          <button
            class="btn btn-success btn-md mr-2"
            :disabled="data.bomdesign.length === 0"
          >
            <i class="fa fa-file-excel"></i>
            Excel
          </button>
        </vue-excel-xlsx>

        <button
          class="btn btn-danger btn-md mr-2"
          @click="onExportPDF"
          :disabled="data.bomdesign.length === 0"
        >
          <i class="fa fa-file-pdf"></i>
          PDF
        </button>
      </div>

      <div class="card-body p-0">
        <div class="table-responsive" ref="contentPDF" id="contentPDF">
          <complete-table :loading="loading" :data="this.data.bomdesign">
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px" class="pl-7">
                  <span class="text-dark-75">No</span>
                </th>
                <th style="min-width: 100px" class="pl-7">
                  <span class="text-dark-75">Numbering</span>
                </th>
                <th style="min-width: 100px">Title</th>
                <th style="min-width: 100px">Station</th>
                <th style="min-width: 100px">Scope</th>
                <th style="min-width: 100px">Type</th>
                <th style="min-width: 80px">Date</th>
                <th style="min-width: 80px">Status</th>
                <th style="min-width: 80px">Revision</th>
                <th style="min-width: 80px">Versi</th>
                <th style="min-width: 80px">Outstanding</th>
              </tr>
            </template>

            <template template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.no }}
                  </span>
                </td>
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.stasiun }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.scope }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.type }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.date }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.versi }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_REPORT_BOM_DESIGN } from "@/core/services/store/reporting-bom-design.module";
import { mapGetters, mapState } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { logos } from "@/assets/js/components/logos64bit.js";
import {
  SHOW_PROJECT_INTERFACE,
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_PLANNER,
  SHOW_PROJECT_DRAFTER,
  SHOW_PROJECT_KOOR,
  SHOW_PROJECT_ENGINER,
  SHOW_PROJECT_MANAGER,
  SHOW_PROJECT_ADMIN,
} from "../../../core/services/store/dl.design.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable.vue";

export default {
  components: { CompleteTable },
  name: "BomDesignReport",
  data() {
    return {
      selectedProject: {
        code_project: null,
        type_document: null,
        project_name: null,
        nickname: null,
        contract_no: null,
        latest: false,
      },
      selectNickname: {
        code: null,
        text: null,
      },
      columns: [
        {
          label: "No",
          field: "no",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Station",
          field: "stasiun",
        },
        {
          label: "Scope",
          field: "scope",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Revision",
          field: "revision",
        },
        {
          label: "Version",
          field: "versi",
        },
        {
          label: "Outstanding",
          field: "outstanding",
        },
      ],
      data: {
        bomdesign: [],
      },
      options: {
        documentTypeOptions: [
          { value: "ALL", text: "All" },
          { value: "ENG", text: "Enginering" },
          { value: "BOM", text: "BOM proyek" },
        ],
        project: [{ value: null, text: null }],
      },
    };
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },
    onPDF() {
      console.log(this.currentReportBomDesign);
    },

    onShowData(latest) {
      if (this.selectedProject?.code_project === null) {
        this.$bvToast.toast("Please Choose Project First.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else if (this.selectedProject?.type_document === null) {
        this.$bvToast.toast("Please Choose BOM Type First.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.selectedProject.latest = latest;
        this.$store
          .dispatch(GET_REPORT_BOM_DESIGN, this.selectedProject)
          .then(() => this.mappingDataBomDesign(this.currentReportBomDesign));
      }
    },

    mappingDataProject(projectList) {
      this.options.project = [];
      projectList.map((project) =>
        this.options.project.push({
          text: project.code + "-" + project.nickname,
          value: project.code,
        })
      );
    },
    mappingDataBomDesign(listBom) {
      let no = 0;
      this.data.bomdesign = [];
      listBom.map((bom) =>
        this.data.bomdesign.push({
          no: (no = no + 1).toString(),
          numbering: bom.numbering,
          title: bom.title,
          stasiun: bom.station === null ? " " : bom.station?.name,
          scope: bom.scope === null ? " " : bom.scope?.name,
          type: bom.type === null ? " " : bom.type?.name,
          date: " ",
          status: bom.last_status === null ? " " : bom.last_status.name,
          revision:
            bom.revision_version === null
              ? " "
              : bom.revision_version.toString(),
          versi: bom.version === null ? " " : bom.version.toString(),
          outstanding: bom.outstanding === null ? " " : bom.outstanding?.name,
        })
      );
    },

    onExportPDF() {
      moment.locale("id");
      /*     var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm */

      var totalPagesExp = "{total_pages_count_string}";
      var doc = new jsPDF("landscape");
      const today = new Date();
      var projectCode =
        this.selectedProject.code_project !== null
          ? this.selectedProject.code_project /*  */
          : "-";
      var nickname =
        this.selectedProject.nickname !== null
          ? this.selectedProject.nickname
          : "-";
      var contract_no =
        this.selectedProject.contract_no !== null
          ? this.selectedProject.contract_no
          : "-";
      var logo = logos;

      doc.setFontSize(10);
      var head = [
        [
          "No",
          "Numbering",
          "Title",
          "Station",
          "Scope",
          "Type",
          "Date",
          "Status",
          "Revision",
          "Versi",
          "Outstanding",
        ],
      ];
      var body = [];
      this.data.bomdesign.map((c) => {
        const propertyValues = Object.values(c);
        body.push(propertyValues);
      });

      doc.autoTable({
        headStyles: {
          halign: "center",
          valign: "middle",
        },
        head: head,
        body: body,
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
          doc.text("Reporting BOM Design", data.settings.margin.left + 80, 25);
          doc.setFontSize(8);

          doc.text(
            `Day/Date : ${moment(today).format("dddd")}/ ${moment(today).format(
              "ll"
            )}`,
            data.settings.margin.left + 200,
            15
          );
          doc.text(
            `Time :  ${moment(today).format("LTS")}`,
            data.settings.margin.left + 200,
            20
          );

          doc.text(
            `Contract No: ${contract_no}`,
            data.settings.margin.left + 200,
            25
          );

          doc.text(
            `Project Code: ${projectCode}`,
            data.settings.margin.left + 200,
            30
          );

          doc.text(
            `Project Name: ${nickname}`,
            data.settings.margin.left + 200,
            35
          );

          /*  var lines = doc.splitTextToSize(`Project name: ${projectName}`, (pdfInMM-lMargin-rMargin));
          doc.text(
            lines,
            data.settings.margin.left,
           45
          ); */

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
          doc.text(str, 260, pageHeight - 10);
          doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 45 },
      });
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }
      var bomDesignName =
        this.selectedProject.code_project +
        "-" +
        this.selectedProject.nickname +
        "- BOM Design.pdf";
      doc.save(bomDesignName);
    },

    createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: "center",
          padding: 0,
        });
      }
      return result;
    },
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BOM Design Report" },
    ]);

    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_PLANNER);
    await this.$store.dispatch(SHOW_PROJECT_DRAFTER);
    await this.$store.dispatch(SHOW_PROJECT_KOOR);
    await this.$store.dispatch(SHOW_PROJECT_ENGINER);
    await this.$store.dispatch(SHOW_PROJECT_MANAGER);
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
  },
  computed: {
    ...mapGetters(["currentReportBomDesign", "currentProjectDetails"]),
    ...mapState({
      loading: (state) => state.reportBomDesign.loading,
    }),
    projectOptions() {
      var result = [{ value: null, text: "Choose Project" }];
      if (this.currentProjectDetails != undefined) {
        this.currentProjectDetails.map((project) => {
          result.push({
            value: project.code,
            text: project.code + "-" + project.nickname,
          });
        });
      }
      return result;
    },
    projectList() {
      let projectInterface = this.$store.getters.getterProjectInterface;
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectPlanner = this.$store.getters.getterProjectPlanner;
      let projectDrafter = this.$store.getters.getterProjectDrafter;
      let projectKoor = this.$store.getters.getterProjectKoor;
      let projectEnginer = this.$store.getters.getterProjectEnginer;
      let projectManager = this.$store.getters.getterProjectManager;
      let projectAdmin = this.$store.getters.getterProjectAdmin;

      let combine1 = this.merge(projectInterface, projectDesign, "code");
      let combine2 = this.merge(combine1, projectPlanner, "code");
      let combine3 = this.merge(combine2, projectDrafter, "code");
      let combine4 = this.merge(combine3, projectKoor, "code");
      let combine5 = this.merge(combine4, projectEnginer, "code");
      let combine6 = this.merge(combine5, projectManager, "code");
      let combine7 = this.merge(combine6, projectAdmin, "code");

      return combine7;
    },
    currentProjectCode() {
      return this.selectNickname.code;
    },
  },
  watch: {
    currentProjectCode(value) {
      if (!value) {
        Object.assign(this.selectedProject, {
          code_project: null,
          project_Name: null,
          nickname: null,
          contract_no: null,
        });

        return;
      }

      var resultProject = this.projectList.filter((c) => c.code === value);

      Object.assign(this.selectedProject, {
        code_project: resultProject[0].code,
        project_name: resultProject[0].name,
        nickname: resultProject[0].nickname,
        contract_no: resultProject[0].contract_no,
      });
    },
  },
};
</script>

<style></style>
